


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { mapState } from 'vuex';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import Tabs from '@/_ui/tabs/tabs.vue';
import chooseCompanyCategories from '@/views/components/popups/chooseCompanyCategories.vue';
import TheNavigation from '@/_modules/promo-hall/components/the-navigation.vue';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TTab } from '@/_ui/tabs/types/tabs.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TPavilion } from '@/_types/promo-page/pavilion.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TEventTag } from '@/_types/event-tag.type';
import SegmentControl from '@/_ui/segment-control/segment-control.vue';
import IconEwStar from '@/_modules/icons/components/icon-ew-star.vue';
import IconEwList from '@/_modules/icons/components/icon-ew-list.vue';
import IconEwMap from '@/_modules/icons/components/icon-ew-map.vue';

type TCategoriesChooserMethods = {
  handleTagSelected: (tagData: TEventTag, index: number) => void;
}

@Component({
  components: {
    TheNavigation,
    SearchBar,
    Tabs,
    chooseCompanyCategories,
    SegmentControl,
    IconEwStar,
    IconEwList,
    IconEwMap
  },
  computed: {
    ...mapState({
      planEventId: state => (state as TAppStoreState).seatplanStore.eventId,
      planJson: state => (state as TAppStoreState).seatplanStore.planJson,
      planIsLoading: state => (state as TAppStoreState).seatplanStore.isLoading,
      pavilions: state => (state as TAppStoreState).promoStore.pavilions,
      companiesListType: state => (state as TAppStoreState).promoStore.companiesListType,
      contactListType: state => (state as TAppStoreState).promoStore.contactListType,
      eventTags: state => (state as TAppStoreState).eventStore.eventTags,
    }),
  },

})
export default class CompaniesViewer extends Vue {

  @Getter('promoPageStore/isMediaBlockOverlayVisible') isCompanyMediaBlockOverlayVisible: boolean;
  @Getter('promoPageStore/isViewTypeMap') isViewTypeMap: boolean;
  @Getter('promoPageStore/isViewTypeList') isViewTypeList: boolean;
  @Getter('seatplanStore/isPlanJson') isPlanJson: boolean;
  @Getter('locationStore/getCountryList') getCountryList: any[];
  @Getter('promoPageStore/getListAll') getListAll: TPromoPage[];
  @Getter('promoPageStore/getListMy') getListMy: TPromoPage[];
  @Getter('promoPageStore/isListTypeAll') isListTypeAll: boolean;
  @Getter('promoPageStore/isListTypeMy') isListTypeMy: boolean;

  @Action('promoPageStore/setListAll') setListAll: () => Promise<TPromoPage[]>;
  @Action('promoPageStore/setListMy') setListMy: () => Promise<TPromoPage[]>;
  @Action('promoStore/promoPageListMy') promoPageListMy: (params: { event_id: string }) => Promise<void>;
  @Action('promoStore/promoPageListAll') promoPageListAll: (params: { event_id: string }) => Promise<void>;
  @Action('promoStore/setCompaniesListType') setCompaniesListType: (listTypeValue: string) => Promise<void>;
  @Action('seatplanStore/getSeatplanJson') getSeatplanJson: (params: { eventId: string }) => Promise<void>;

  // vars from mapState
  public planEventId: string;
  public planJson: any;
  public planIsLoading: boolean;
  public pavilions: TApiListResponse<TPavilion>;
  public companiesListType: string;
  public contactListType: string; // TODO: do we need it here?
  public eventTags: TEventTag[];

  // data
  public selectedTags: number[] | TEventTag[] = [];
  public showCompanyFilters: boolean = false;
  public pavilion_id: number = null;
  public isPromoPageNavigationInvisible: boolean = false;
  public companyListTypeTabs: TTab[] = [
    {
      title: this.$t('navigation.all'),
      isActive: true,
      index: 0,
    },
    {
      title: this.$t('navigation.my'),
      isActive: false,
      index: 1,
    },
  ];
  public companyViewTypeTabs: TTab[] = [
    {
      title: this.$t('navigation.list'),
      isActive: true,
      index: 0,
    },
    {
      title: this.$t('navigation.map'),
      isActive: false,
      index: 1,
    },
  ];

  public get isFilterSearchDisabled(): boolean {
    return false; // TODO: real logic
  }

  public get isCompanyCategoriesChooserVisible(): boolean {
    return !!(this.$route.meta && this.$route.meta.isCompanyCategoriesChooserVisible);
  }

  @Watch('planJson', { immediate: true })
  public onPlanJsonChange(): void {
    if (
      this.$route.params.external_id
      || (
        this.planEventId === this.$route.params.eventId
        && !this.planIsLoading
        && !this.planJson
      )
    ) {
      this.setCompaniesListType('all');

    } else {
      this.companyViewTypeTabs[1].isActive = true;
      this.companyViewTypeTabs[0].isActive = false;
    }
  }

  public created(): void {
    this.promoPageListAll({
      event_id: this.$route.params.eventId,
    });

    this.getSeatplanJson({
      eventId: this.$route.params.eventId,
    });

    if (this.$route.params.external_id) {
      // TODO: leave only one store for this
      this.setCompaniesListType('all');
      this.callSetViewTypeMutation('list');
      this.callSetListTypeMutation('all');
    }

    this.$store.dispatch('eventStore/callEventTags', this.$route.params.eventId);
    this.$store.dispatch('promoStore/getPavilion', { eventId: this.$route.params.eventId });
  }

  @Watch('planIsLoading')
  public onPlanIsLoadingChange(newVal: boolean, oldVal: boolean): void {
    if (!newVal && oldVal && !this.isPlanJson) {
      this.callSetViewTypeMutation('list');
    }
  }

  public callSetViewTypeMutation(viewTypeValue: string): void {
    this.$store.commit('promoPageStore/SET_VIEW_TYPE', viewTypeValue);
  }

  public callSetListTypeMutation(listTypeValue: string): void {
    this.$store.commit('promoPageStore/SET_LIST_TYPE', listTypeValue);
  }

  public get querySearch(): string {
    return ('' + (this.$route.query.search || '')).trim();
  }

  public onSearchClick(value: string): void {
    const searchString = (value || '').trim();
    const trimmed = value.trim();
    let countryId: number = null;

    this.getCountryList.find(country => {
      if (country.name.toLowerCase() === trimmed.toLowerCase()) {
        countryId = country.id;
      }
    });

    this.$store.commit('promoPageStore/SET_SEARCH', { value: trimmed, countryId: countryId });
    this.onViewList();
    this.companyListTypeTabs.forEach(tab => {
      tab.isActive = tab.index === 0;
    });

    if (searchString !== this.querySearch) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: Object.assign({}, this.$route.query, {
          search: searchString || undefined,
        }),
      });
    }
  }

  public onTagsFilterClick(): void {
    this.showCompanyFilters = !this.showCompanyFilters;
  }

  public get isAllCompaniesTabActive(): boolean {
    return !!(this.companyListTypeTabs.find(tab => tab.isActive && tab.index === 0));
  }

  public get isMyCompaniesTabActive(): boolean {
    return !!(this.companyListTypeTabs.find(tab => tab.isActive && tab.index === 1));
  }

  public get isListTabActive(): boolean {
    return !!(this.companyViewTypeTabs.find(tab => tab.isActive && tab.index === 0));
  }

  public get isMapTabActive(): boolean {
    return !!(this.companyViewTypeTabs.find(tab => tab.isActive && tab.index === 1));
  }

  public onCompanyListTypeActiveTabUpdate(activeTab: TTab, activeTabIndex: number): void {
    this.companyListTypeTabs.forEach((tab, idx) => {
      tab.isActive = idx === activeTabIndex;
    });

    this.dispatchActiveListTypeTabClickHandlers();
  }

  public dispatchActiveListTypeTabClickHandlers(): void {
    if (this.isAllCompaniesTabActive) {
      this.onAllCompaniesClick();
      return;
    }
    if (this.isMyCompaniesTabActive) {
      this.onMyCompaniesClick();
    }
  }

  public onCompanyViewTypeActiveTabUpdate(activeTab: TTab, activeTabIndex: number): void {
    this.companyViewTypeTabs.forEach((tab, idx) => {
      tab.isActive = idx === activeTabIndex;
    });
    this.dispatchActiveViewTypeTabClickHandlers();
  }

  public dispatchActiveViewTypeTabClickHandlers(): void {
    if (this.isListTabActive) {
      this.onViewList();
      return;
    }
    if (this.isMapTabActive) {
      this.onViewMap();
    }
  }

  public onAllCompaniesClick(): void {
    this.onRoutePromoCompanies();
    this.$store.commit('promoPageStore/RESET_LIST_ALL');
    this.$store.commit('promoPageStore/SET_LIST_TYPE', 'all');
    this.setListAll();
  }

  public onMyCompaniesClick(): void {
    this.onRoutePromoCompanies();
    this.$store.commit('promoPageStore/RESET_LIST_MY');
    this.$store.commit('promoPageStore/SET_VIEW_TYPE', 'list');
    this.$store.commit('promoPageStore/SET_LIST_TYPE', 'my');
    this.setListMy();
  }

  public onRoutePromoCompanies(): void {
    this.$router.push({
      name: 'promo-page-events-companies',
    }).catch(() => {
      /* ignore */
    });
  }

  public onPavilionChange(pavilionId: number): void {
    this.$store.commit('promoPageStore/SET_PAVILION_ID', pavilionId);
    this.onViewList();
  }

  public onViewMap(): void {
    this.callSetViewTypeMutation('map');
    this.callSetListTypeMutation('all');
  }

  public onViewList(): void {
    this.callSetViewTypeMutation('list');
    this.onAllCompaniesClick();
  }

  public filterTags(tags: TEventTag[]): void {
    const tagIds: number[] = [];
    tags.forEach(tag => {
      if (tag.isSelected) {
        tagIds.push(tag.id);
      }
    });
    this.selectedTags = tagIds;
    this.$store.commit('promoPageStore/SET_FILTER_IDS', tagIds);
    this.onViewList();
  }

  resetSelectedTags(tags: TEventTag[]): void {
    const categories: Vue = this.$refs.chooseCompanyCategories as Vue;
    tags.forEach((tag, index) => {
      ((categories as unknown) as TCategoriesChooserMethods).handleTagSelected({
        ...tag,
        isSelected: false,
      }, index);
    });

    this.selectedTags = [];

    this.showCompanyFilters = false;
    this.$store.commit('promoPageStore/SET_FILTER_IDS', []);
    this.onViewList();
  }

};
