







































































































































// @ts-nocheck
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import chooseCompanyCategories from '@/views/components/popups/chooseCompanyCategories.vue';
import _cloneDeep from 'lodash.clonedeep';

export default {
  name: 'promo-nav-bar',
  components: {
    chooseCompanyCategories
  },
  computed: {
    ...mapState({
      planEventId: state => state.seatplanStore.eventId,
      planJson: state => state.seatplanStore.planJson,
      planIsLoading: state => state.seatplanStore.isLoading,
      companiesListType: state => state.promoStore.companiesListType,
      contactListType: state => state.promoStore.contactListType,
      eventTags: state => state.eventStore.eventTags,
      pavilions: state => state.promoStore.pavilions,
    }),
    ...mapGetters({
      eventContactTags: '_eventStore/eventContactTags',
      isPlanJson: 'seatplanStore/isPlanJson',
      isViewTypeMap: 'promoPageStore/isViewTypeMap',
      isViewTypeList: 'promoPageStore/isViewTypeList',
      getListAll: 'promoPageStore/getListAll',
      getListMy: 'promoPageStore/getListMy',
      isListTypeAll: 'promoPageStore/isListTypeAll',
      isListTypeMy: 'promoPageStore/isListTypeMy',
      getCountryList: 'locationStore/getCountryList'
    }),
    isMapAndListMenuVisible(): boolean {
      const eventId = this.$route.params.eventId;
      if (
        eventId !== this.planEventId
        || this.planIsLoading
        || !this.planJson
      ) {
        return false;
      }

      return (
        this.$route.name === 'promo-page-events-companies'
        || this.$route.name === 'promo-page-events-company'
        || this.$route.name === 'promo-profile'
      );
    },
    showCompaniesFilterButton(): boolean {
      return this.$route.name === 'promo-page-events-companies' && this.eventTags && this.eventTags.length;
    },
    showContactsFilterButton(): boolean {
      const isRouteNameWhitelisted = this.$route.name === 'promo-contacts' || this.$route.name === 'promo-contact-company' || this.$route.name === 'promo-page-contacts-contact';
      const isContactTagsPresent = this.eventContactTags && this.eventContactTags.length;
      return isRouteNameWhitelisted && isContactTagsPresent;
    }
  },
  watch: {
    eventContactTags: {
      handler(): any {
        this.eventContactTagsLocal = _cloneDeep(this.eventContactTags).map((item) => {
          return {
            ...item,
            isSelected: false,
          };
        });
      }
    }
  },
  data(): any {
    return {
      showCompanyFilters: false,
      showContactFilters: false,
      selectedTags: [],
      pavilion_id: null,
      eventContactTagsLocal: [],
      search: ''
    };
  },
  created(): void {
    this.$store.dispatch('eventStore/callEventTags', this.$route.params.eventId);
    this.$store.dispatch('_eventStore/getEventContactTags');
    this.$store.dispatch('promoStore/getPavilion', {eventId: this.$route.params.eventId});
  },
  mounted(): void {
    if (!this.isPlanJson) {
      this.SET_VIEW_TYPE('list');
    }

    this.$store.dispatch('locationStore/countryList');
  },
  methods: {
    ...mapActions({
      setListAll: 'promoPageStore/setListAll',
      setListMy: 'promoPageStore/setListMy',
      promoPageListMy: 'promoStore/promoPageListMy',
      promoPageListAll: 'promoStore/promoPageListAll',
    }),
    ...mapMutations({
      SET_VIEW_TYPE: 'promoPageStore/SET_VIEW_TYPE',
      SET_LIST_TYPE: 'promoPageStore/SET_LIST_TYPE',
      SET_SEARCH: 'promoPageStore/SET_SEARCH',
      SET_FILTER_IDS: 'promoPageStore/SET_FILTER_IDS',
      RESET_LIST_ALL: 'promoPageStore/RESET_LIST_ALL',
      RESET_LIST_MY: 'promoPageStore/RESET_LIST_MY',
      SET_PAVILION_ID: 'promoPageStore/SET_PAVILION_ID'
    }),

    onRoutePromoCompanies(): void {
      this.$router.push({name: 'promo-page-events-companies'}).catch(() => {
        /* ignore */
      });
    },

    onListTypeAll(): void {
      this.onRoutePromoCompanies();
      this.RESET_LIST_ALL();
      this.SET_LIST_TYPE('all');
      this.setListAll();
    },

    onListTypeMy(): void {
      this.onRoutePromoCompanies();
      this.RESET_LIST_MY();
      this.SET_VIEW_TYPE('list');
      this.SET_LIST_TYPE('my');
      this.setListMy();
    },

    onViewMap(): void {
      this.SET_VIEW_TYPE('map');
      this.SET_LIST_TYPE('all');
    },

    onViewList(): void {
      this.SET_VIEW_TYPE('list');
      this.onListTypeAll();
    },

    onSearch(): void {

      const trimed = this.search.trim();
      let countryId: number = null;
      this.getCountryList.find(country => {
        if (country.name.toLowerCase() === trimed.toLowerCase()) {
          countryId = country.id;
        }
      });

      this.SET_SEARCH({value: this.search, countryId: countryId});
      this.onViewList();
    },

    filterTags(tags: []): void {
      const tagIds = [];
      tags.filter(item => {
        if (item.isSelected) {
          tagIds.push(item.id);
        }
      });
      this.selectedTags = tagIds;
      this.SET_FILTER_IDS(tagIds);
      this.onViewList();
    },

    onPavilionChange(pavilionId: null | number): void {
      this.SET_PAVILION_ID(pavilionId);
      this.onViewList();
    },

    resetSelectedTags(tags: []): void {
      const categories = this.$refs.chooseCompanyCategories;
      this.selectedTags = tags.map((item, index) => {
        item.isSelected = false;
        categories.handleTagSelected({item}, index);
      });
      this.showCompanyFilters = false;
      this.SET_FILTER_IDS([]);
      this.onViewList();
    }
  }
};
