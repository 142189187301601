


import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator';
import { TTab } from '@/_ui/tabs/types/tabs.type';

@Component
export default class Tabs extends Vue {
  @Ref('tabs-wrapper') tabsWrapper: HTMLElement;
  @Ref('tabs-underline') tabsUnderline: HTMLElement;

  @Prop({ type: Array, default: (): TTab[] => [] })
  public tabList: TTab[];

  public get activeTabNumber(): number {
    return this.tabList.findIndex(tab => tab.isActive) || 0;
  }

  @Watch('activeTabNumber', { immediate: true })
  private onActiveTabNumberChange(): void {
    this.moveTabUnderline();
  }

  public mounted(): void {
    window.setTimeout(() => {
      this.moveTabUnderline();
    }, 500);
  }

  public emitActiveTab(tab: TTab, index: number): void {
    this.$emit('active-tab', tab, index);
  }

  public moveTabUnderline(): void {
    if (!this.tabsWrapper) {
      return;
    }
    // @ts-ignore N.B. Because we are not sure if $ref.tabsWrapper is a Vue instance or an HTMLElement. Todo: remove ts-ignore if possible
    const tabsWrapper: HTMLElement = this.tabsWrapper.$el ? this.tabsWrapper.$el : this.tabsWrapper;
    const tabs: HTMLCollection = tabsWrapper.children; // TODO: filter only elements classList.contains('tab')
    const activeTab: HTMLElement = tabs[this.activeTabNumber] as HTMLElement;
    const rect = activeTab.getBoundingClientRect();
    this.tabsUnderline.style.width = rect.width + 'px';
    this.tabsUnderline.style.marginLeft = activeTab.offsetLeft + 'px';
  }
}

